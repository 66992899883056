@charset "utf-8";



// Our variables
$base-font-family: "PT Sans", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;
// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "color"
;


/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    text-decoration: none;

    &:hover {
        border-bottom: 1px dotted;
    }

    &.page-link:hover {
        border-bottom: 1px dotted;
    }
    &.postLink:hover{
        border-bottom: 1px dotted;
    }
    &.post-title-link:hover{
        border-bottom: 1px dotted !important;
    }
}



/**
 * Blockquotes
 */
blockquote {
    border-left: 4px solid;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid;
    border-radius: 3px;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
}

.post {
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 300;
  padding-top: 0;
}
.home{
  padding-bottom: 5em;
}
.download {
  padding: 10px;
}
.authorKeyword {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  text-indent: 2px;
  padding-top:0.5em;
}
.writtenBy {
  letter-spacing: 0;
  text-indent: 0;
  text-transform: uppercase;
}
.archive-intro {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-indent: 2px;
}
.archiveIntro {
  text-align: center;
}
.hint {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  text-indent: 2px;
}
.example {
  font-family: 'Menlo';
  padding: 10px;
}
.manual {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  text-indent: 2px;
}
.postContent {
  padding-top: 3.5em;
  text-align: center;
}
.postDate {
  float: left;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
  text-indent: 2px;
}
.postTag {
  float: right;
}
.postTitle {
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-indent: 3px;
}
/**
 * Site header
 */
.site-header {
  border-top: 5px solid;
  border-bottom: 1px solid;
  min-height: 56px;
  // Positioning context for the mobile navigation icon
  position: relative;
}
.site-title {
  font-size: 64px;
  font-weight: 100;
  font-family: 'PT Sans';
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 1em;
}
.site-description {
  font-size: 14px;
  letter-spacing: 0px;
  text-indent: 0px;
  font-family: 'Josefin Sans';
}
.exclamationMark {
  padding-left: 2em;
  padding-right: 2em;
}
.site-navigation {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  text-indent: 6px;
  font-family: 'Josefin Sans';
  font-weight: 300;
  padding-top: 5em;
}
.page-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-indent: 2px;
  font-family: "PT Sans";
  font-size: 12px;
  text-align:center;
}
.page-tagline {
  font-size: 18px;
  font-family: "PT Sans";
  text-align: center;
  text-transform: lowercase;
}
.site-nav {
  .page-link {
    line-height: $base-line-height;
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    border-radius: 5px;
    text-align: right;
    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;
      > svg {
        width: 18px;
        height: 15px;
      }
    }
    .trigger {
      clear: both;
      display: none;
    }
    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }
    .page-link {
      display: block;
      padding: 5px 10px;
      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}
/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid;
  padding: $spacing-unit 0;
}
.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}
.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}
.small-site-title {
  font-family: "PT Sans";
}
.footer-col-wrapper {
  font-size: 15px;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}
.footer-content {
  font-family: "Josefin Sans";
  font-weight: 300;
  letter-spacing: 1px;
}
.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}
.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}
.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}
.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}
@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }
  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}
@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}
/**
 * Page content
 */
.page-content {
  padding: 0;
}
.page-heading {
  font-size: 20px;
}
.post-list {
  margin-left: 0;
  list-style: none;
  > li {
    margin-bottom: $spacing-unit;
  }
}
.post-meta {
  font-size: $small-font-size;
  text-transform: uppercase;
  font-size: 16px;
}
.post-link {
  display: block;
  font-size: 24px;
}
/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}
.post-title {
  font-size: 62px;

  text-align: center;
  font-family: "PT Sans";
  font-weight: 900;
  @include media-query($on-laptop) {
    font-size: 36px;
  }
}
.post-content {
  margin-bottom: $spacing-unit;
  padding-bottom: 3em !important;
  p {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 32px;
    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }
  h3 {
    font-size: 26px;
    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }
  h4 {
    font-size: 20px;
    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

/**
 * Pagination
 */
 .pagination {
     padding-top: 3.5em;
     text-align: center;
 }
 .paginationLink {
     border: 0;
     display: inline-block;
     padding: 5px;
     text-decoration: none;
     transition: color 200ms ease-out;

     &:hover,
     &:active,
     &:focus {
         border: 0;
     }
 }
 .paginationLinkCurrent {
     font-style: normal;
 }

.postNavigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3em;

    a:only-child {
        width: 100%;
    }
}
.postPrev,
.postNext {
    display: inline-block;
    width: 49%;
    &:hover,
    &:active,
    &:focus {
        border: 0;
    }
}
.postNext {
    text-align: right;
}

/**
 * Syntax highlighting styles
 */
.highlight {
    @extend %vertical-rhythm;

    .c     { color: #998; font-style: italic } // Comment
    .err   { color: #a61717; background-color: #e3d2d2 } // Error
    .k     { font-weight: bold } // Keyword
    .o     { font-weight: bold } // Operator
    .cm    { color: #998; font-style: italic } // Comment.Multiline
    .cp    { color: #999; font-weight: bold } // Comment.Preproc
    .c1    { color: #998; font-style: italic } // Comment.Single
    .cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
    .gd    { color: #000; background-color: #fdd } // Generic.Deleted
    .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
    .ge    { font-style: italic } // Generic.Emph
    .gr    { color: #a00 } // Generic.Error
    .gh    { color: #999 } // Generic.Heading
    .gi    { color: #000; background-color: #dfd } // Generic.Inserted
    .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
    .go    { color: #888 } // Generic.Output
    .gp    { color: #555 } // Generic.Prompt
    .gs    { font-weight: bold } // Generic.Strong
    .gu    { color: #aaa } // Generic.Subheading
    .gt    { color: #a00 } // Generic.Traceback
    .kc    { font-weight: bold } // Keyword.Constant
    .kd    { font-weight: bold } // Keyword.Declaration
    .kp    { font-weight: bold } // Keyword.Pseudo
    .kr    { font-weight: bold } // Keyword.Reserved
    .kt    { color: #458; font-weight: bold } // Keyword.Type
    .m     { color: #099 } // Literal.Number
    .s     { color: #d14 } // Literal.String
    .na    { color: #008080 } // Name.Attribute
    .nb    { color: #0086B3 } // Name.Builtin
    .nc    { color: #458; font-weight: bold } // Name.Class
    .no    { color: #008080 } // Name.Constant
    .ni    { color: #800080 } // Name.Entity
    .ne    { color: #900; font-weight: bold } // Name.Exception
    .nf    { color: #900; font-weight: bold } // Name.Function
    .nn    { color: #555 } // Name.Namespace
    .nt    { color: #000080 } // Name.Tag
    .nv    { color: #008080 } // Name.Variable
    .ow    { font-weight: bold } // Operator.Word
    .w     { color: #bbb } // Text.Whitespace
    .mf    { color: #099 } // Literal.Number.Float
    .mh    { color: #099 } // Literal.Number.Hex
    .mi    { color: #099 } // Literal.Number.Integer
    .mo    { color: #099 } // Literal.Number.Oct
    .sb    { color: #d14 } // Literal.String.Backtick
    .sc    { color: #d14 } // Literal.String.Char
    .sd    { color: #d14 } // Literal.String.Doc
    .s2    { color: #d14 } // Literal.String.Double
    .se    { color: #d14 } // Literal.String.Escape
    .sh    { color: #d14 } // Literal.String.Heredoc
    .si    { color: #d14 } // Literal.String.Interpol
    .sx    { color: #d14 } // Literal.String.Other
    .sr    { color: #009926 } // Literal.String.Regex
    .s1    { color: #d14 } // Literal.String.Single
    .ss    { color: #990073 } // Literal.String.Symbol
    .bp    { color: #999 } // Name.Builtin.Pseudo
    .vc    { color: #008080 } // Name.Variable.Class
    .vg    { color: #008080 } // Name.Variable.Global
    .vi    { color: #008080 } // Name.Variable.Instance
    .il    { color: #099 } // Literal.Number.Integer.Long
}

.portfolio-tag {
  font-size: 0.8em;
  font-style: italic;
  color: #848484;
}